<template>
    <div>
     <p class="text">Enter minimum 3 characters of Affiliation Name, then select the Affiliation name to get the details</p>
      <div class="search-input white w-100 relative" v-click-outside="onClickOutside">
        <input type="text" v-model="affiliationInput" @change="optionSelected" placeholder="Enter min three character" @focus="focusInput() ">
                <div class="search-img" @click="searchAffilation()" title="Search">
        <img class="search_img" :src="require(`../assets/white_search_icon.svg`)" alt="Search">
      </div>
      <div class="search-dropdown-box scroll-verticle-bar" v-if="showAffiliationDropdown">
            <p class="red-text drop-validation text-center" v-if="(AffilationUserList.length === 0)">Affiliation not found!</p>
            <div v-for="user in AffilationUserList" :key="user.id" class="searched-item pointer">
                <p class="truncate" :title="user.Name" @click="getAffiliationDetails(user)">{{ user.Name }}</p>
            </div>
        </div>
    </div>
      <div v-if="affiliationDetails">
        <h4>Affiliation Details</h4>
        <p>Affiliation ID: {{affiliationId}}</p>
        <p>Name: <b>{{affiliationName}}</b></p>
        <h4>Institute Test Details</h4>
        <table>
          <thead>
            <tr>
              <th>Month Name</th>
              <th>Year</th>
              <th>Number of Tests</th>
              <th>Assigned</th>
              <th>Attempted</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(instituteTestDetail, index) in affiliationDetails.instituteTestDetails" :key="index">
              <td>{{instituteTestDetail.monthName}}</td>
              <td>{{instituteTestDetail.year}}</td>
              <td>{{instituteTestDetail.numberOfTests}}</td>
              <td>{{instituteTestDetail.assigned}}</td>
              <td>{{instituteTestDetail.attempted}}</td>
            </tr>
          </tbody>
        </table>
        <h4>Live Class Details</h4>
        <table>
          <thead>
            <tr>
              <th>Month Name</th>
              <th>Year</th>
              <th>Number of Classes</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(liveClassDetail, index) in affiliationDetails.liveClassDetails" :key="index">
              <td>{{liveClassDetail.monthName}}</td>
              <td>{{liveClassDetail.year}}</td>
              <td>{{liveClassDetail.numberOfClasses}}</td>
            </tr>
          </tbody>
        </table>
        <h4>Active User Details</h4>
        <table>
          <thead>
            <tr>
              <th>Month Name</th>
              <th>Year</th>
              <th>Active users</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(activeUserDetail, index) in affiliationDetails.activeUserDetails" :key="index">
              <td>{{activeUserDetail.monthName}}</td>
              <td>{{activeUserDetail.year}}</td>
              <td>{{activeUserDetail.activeUsers}}</td>
            </tr>
          </tbody>
        </table>
        <h4>Various role Details</h4>
        <table>
          <thead>
            <tr>
              <th>Created On</th>
              <th>Students</th>
              <th>Deactive demo students</th>
              <th>Demo students</th>
              <th>Affiliation Head</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(roleDetail, index) in affiliationDetails.roleDetails" :key="index">
              <td>{{createdOn}}</td>
              <td>{{roleDetail.student}}</td>
              <td>{{roleDetail.deactiveDemo}}</td>
              <td>{{roleDetail.demoStudent}}</td>
              <td>{{roleDetail.affiliationHead}}</td>
            </tr>
          </tbody>
        </table>
        <h4>Attempted Question Details</h4>
        <table>
          <thead>
            <tr>
              <th>Month Name</th>
              <th>Year</th>
              <th>No of students</th>
              <th>No of Attempted Questions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(attemptedQuestionDetail, index) in affiliationDetails.attemptedQuestionDetails" :key="index">
              <td>{{attemptedQuestionDetail.monthName}}</td>
              <td>{{attemptedQuestionDetail.year}}</td>
              <td>{{attemptedQuestionDetail.students}}</td>
              <td>{{attemptedQuestionDetail.attemptedQuestions}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
<script>
import moment from "moment";
import Mobileapi from "../Mobileapi";

export default {
  data() {
    return {
      affiliationInput: "",
      affiliationDetails: null,
      AffilationUserList: [],
      showAffiliationDropdown: false,
      tempShowDropdown: false,
      showAddBox: false,
      affiliationName: "",
      createdOn: "",
    };
  },
  methods: {
    getAffiliationDetails(user) {
      this.$store.dispatch("showLoader", true);
      this.affiliationDetails = [];
      this.showAffiliationDropdown = false;
      this.affiliationId = user.Id;
      this.affiliationName = user.Name;
      Mobileapi.getAffiliationData({ affiliationId: this.affiliationId },
        (response) => {
          this.$store.dispatch("showLoader", false);
          if (response.responseCode === 200) {
            this.affiliationDetails = response.data;
            this.createdOn = this.createdOn ? moment(response.data.roleDetails[0].createdOn).format("DD-MM-YYYY") : "-";
          } else {
            window.M.toast({
              html: "Some error occured.",
            });
          }
        });
    },
    focusInput() {
      this.tempShowDropdown = true;
      this.showAddBox = false;
      if (this.affiliationInput.length > 2) {
        this.searchAffilation();
      }
    },
    searchAffilation() {
      this.showAffiliationDropdown = true;
      Mobileapi.searchAffilations({ searchText: this.affiliationInput }, (response) => {
        this.AffilationUserList = response.data;
      });
    },
    onClickOutside() {
      this.showAffiliationDropdown = false;
    },
  },
  watch: {
    affiliationInput(input) {
      if (input.length > 2 && this.tempShowDropdown) {
        this.showAffiliationDropdown = true;
        Mobileapi.searchAffilations({ searchText: input }, (response) => {
          this.AffilationUserList = response.data;
        });
      } else {
        this.showAffiliationDropdown = false;
        this.AffilationUserList = [];
      }
    },
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
.search-input{
    max-width: 279px;
    width: 100%;
      border: 1px solid #E0E4F0;
      border-radius: 4px;
      height: 40px;
      margin-right: 17px;
      padding-left: 15px;
    }
    .search-img{
        position: absolute;
        right: -1px;
        background: #D9D9D9;
        height: 40px;
        width: 40px;
        line-height: 50px;
        top: -1px;
        text-align: center;
        border-radius: 0 3px 3px 0px;
        cursor: pointer;
    }
    .search-dropdown-box {
          border-radius: 4px;
          position: absolute;
          background: #fff;
          top: 42px;
          width: 279px;
          left: -1px;
          margin-top: -1px;
          box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
          max-height: 200px;
          z-index: 99;
          padding-left: 15px;
          border-bottom: none;
      }
      .text {
margin-top:15px;
      }
      input[type=text]:not(.browser-default) {
        border-bottom: none;
      }
</style>
