<template>
<div>
    <ReportHeader ReportTitle="Institute Test Performance" @TableBody="getInstituteBodyData($event)" @formSubmitTrigger="getFormSubmitTrigger($event)" @selectedMultipleSubjectId="selectedMultipleSubjectId($event)" />
    <div class="chapter-coverage-content" v-if="formSubmitInfo">
        <div class="row">
            <div class="col s12" style="padding:0">
                <table class="qtl" style="width: 100%">
                    <thead>
                        <tr>
                            <th class="left-align" style="text-transform: capitalize;">Student</th>
                            <th class="center-align" style="text-transform: capitalize;">Subject</th>
                            <th class="center">
                                Qs Viewed <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="SortByQsViewed()">swap_vert</span>
                            </th>
                            <th class="center-align">
                                Qs Attempted <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="QsAttempt()">swap_vert</span>
                            </th>
                            <th class="center-align">
                                Correct <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="Correct()">swap_vert</span>
                            </th>
                            <th class="center-align">
                                Accuracy <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="SortByAccuracy()">swap_vert</span>
                            </th>
                            <th class="center-align">
                                National Accuracy <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="SortByGlobalAccuracy()">swap_vert</span>
                            </th>
                            <th class="center-align">
                                Time Spend(in hrs) <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="SortByTimeSpend()">swap_vert</span>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="card-panel">
                        <tr v-for="(item, index) in tableBody" :key="index" style="border-radius: 100px">
                            <td class=" size" style="font-size: 14px; font-weight: 600; color: #642c90;     text-transform: capitalize;">
                                {{item.UserName}}
                            </td>
                             <td class="center" style="color: #333333;">
                                {{item.subjectName}}
                            </td>
                            <td class="center" style="color: #333333;">
                                {{item.Questions}}
                            </td>
                            <td class="center" style="color: #37BC16;">
                                {{item.Attempted}}
                            </td>
                            <td class="center" style="color: #642C90;">
                                {{item.Correct}}
                            </td>
                            <td class="center" style="color: #642C90;">
                                {{item.Accuracy}}%
                            </td>
                            <td class="center" style="color: #642C90;">
                                {{item.GlobalAccuracy}}%
                            </td>
                            <td class="center" style="color: #642C90;">
                                {{formatTime(item.TimeSpentInHours)}}
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import Vue from "vue";

import ReportHeader from "./ReportHeader.vue";

// Vue.filter("formatTime", (value) => {
//   if (value) {
//     const decimalTimeString = value;
//     const n = new Date(0, 0);
//     n.setSeconds(+decimalTimeString * 60 * 60);
//     return n.toTimeString().slice(0, 5);
//   }
//   return 0;
// });
export default {
  name: "ReportPracticeCoverage",
  components: {
    ReportHeader,
  },
  data() {
    return {
      tableHeader: [],
      tableBody: [],
      tableBodyCloneData: [],
      formSubmitInfo: false,
      sortedData: [],
      sortByOption: "",
      isSortClicked: false,
      selectedSubjectId: [],
    };
  },
  activated() {
    this.tableBody = [];
    this.formSubmitInfo = false;
  },
  methods: {
    formatTime(value) {
      if (value) {
        const decimalTimeString = value;
        const n = new Date(0, 0);
        n.setSeconds(+decimalTimeString * 60 * 60);
        return n.toTimeString().slice(0, 5);
      }
      return 0;
    },
    getInstituteBodyData(tbody) {
      this.tableBody = tbody;
      this.tableBodyCloneData = tbody;
      if (this.tableBody !== []) {
        this.tableBody = this.tableBody.filter((item) => item.SubjectId === 0);
      }
    },
    getFormSubmitTrigger(triggerBoolean) {
      this.formSubmitInfo = triggerBoolean;
    },
    selectedMultipleSubjectId(subjectId) {
      if (subjectId.length > 0) {
        this.selectedSubjectId = subjectId;
        const newData = [];
        this.selectedSubjectId.forEach((item) => {
          newData.push(this.tableBodyCloneData.filter((item1) => item1.SubjectId === item));
        });
        const temData = newData.flat(1);
        if (temData) {
          this.tableBody = [];
          this.tableBody = temData;
        }
      } else {
        this.getInstituteBodyData(this.tableBodyCloneData);
        this.selectedSubjectId = [];
      }
    },
    SortByQsViewed() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.Questions - b.Questions);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.Questions - a.Questions);
        this.isSortClicked = true;
      }
    },
    Correct() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.Correct - b.Correct);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.Correct - a.Correct);
        this.isSortClicked = true;
      }
    },
    SortByAccuracy() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.Accuracy - b.Accuracy);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.Accuracy - a.Accuracy);
        this.isSortClicked = true;
      }
    },
    SortByGlobalAccuracy() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.GlobalAccuracy - b.GlobalAccuracy);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.GlobalAccuracy - a.GlobalAccuracy);
        this.isSortClicked = true;
      }
    },
    SortByTimeSpend() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.TimeSpentInHours - b.TimeSpentInHours);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.TimeSpentInHours - a.TimeSpentInHours);
        this.isSortClicked = true;
      }
    },
    QsAttempt() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.Attempted - b.Attempted);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.Attempted - a.Attempted);
        this.isSortClicked = true;
      }
    },
  },
};
</script>

<style scoped>
* {
    box-sizing: border-box;
}

td {
    border-radius: 0px;
}

table.qtl {
    border-collapse: separate;
    border-spacing: 0 10px;
}

thead {
    border-radius: 5px 0 0 5px;
}

table.qtl thead tr {
    background-color: #6a2f85;
    color: #fff;
}

table.qtl thead tr th {
    border-radius: 0;
}

.size {
    width: 200px;
}

.icon {
    cursor: pointer;
}
</style>
