<template>
  <div>
    <ReportHeader
      ReportTitle="Past Week Practice"
      @TableBody="getPastWeekData($event)"
      @formSubmitTrigger="getFormSubmitTrigger($event)"
      @selectedMultipleSubjectId="selectedMultipleSubjectId($event)"
    />
    <div class="chapter-coverage-content" v-if="formSubmitInfo">
      <div class="row">
        <div class="col s12" style="padding: 0">
          <h1
            style="
              color: #333333;
              margin: 25px 0 10px;
              font-size: 16px;
              font-weight: 600;
              font-family: 'Open Sans';
            "
          >
            One Week Practice Activity
            <span style="color: #642c90">({{ pastDate }} to {{ todayDate }})</span>
          </h1>
          <table class="qtl" style="width: 100%">
            <thead>
              <tr>
                <th class="left-align" style="text-transform: capitalize">Student</th>
                <th class="center-align" style="text-transform: capitalize">Subject</th>
                <th class="center-align">
                  Qs Attempted
                  <span
                    class="material-icons"
                    style="position: relative; top: 6px; margin-left: 2px; cursor: pointer"
                    @click="QsAttempt()"
                    >swap_vert</span
                  >
                </th>
                <th class="center">
                  Qs Correct
                  <span
                    class="material-icons"
                    style="position: relative; top: 6px; margin-left: 2px; cursor: pointer"
                    @click="SortByQsCorrect()"
                    >swap_vert</span
                  >
                </th>
                <th class="center-align">
                  Accuracy
                  <span
                    class="material-icons"
                    style="position: relative; top: 6px; margin-left: 2px; cursor: pointer"
                    @click="SortByAccuracy()"
                    >swap_vert</span
                  >
                </th>
                <th class="center-align">
                  National Accuracy
                  <span
                    class="material-icons"
                    style="position: relative; top: 6px; margin-left: 2px; cursor: pointer"
                    @click="SortByGlobalAccuracy()"
                    >swap_vert</span
                  >
                </th>
                <th class="center-align">
                  Practice Time (in hrs)
                  <span
                    class="material-icons"
                    style="position: relative; top: 6px; margin-left: 2px; cursor: pointer"
                    @click="SortByPracticeTime()"
                    >swap_vert</span
                  >
                </th>
                <th class="center-align">
                  Chapters practiced
                  <span
                    class="material-icons"
                    style="position: relative; top: 6px; margin-left: 2px; cursor: pointer"
                    @click="sortByChaptersPracticed()"
                    >swap_vert</span
                  >
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody class="card-panel">
              <tr v-for="(item, index) in tableBody" :key="index" style="border-radius: 100px">
                <td
                  class="size"
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    color: #642c90;
                    text-transform: capitalize;
                  "
                >
                  {{ item.UserName }}
                </td>
                <td class="center" style="color: #333333">
                  {{ item.subjectName }}
                </td>
                <td class="center" style="color: #333333">
                  {{ item.QuestionsAttempted }}
                </td>
                <td class="center" style="color: #37bc16">
                  {{ item.QuestionsCorrect }}
                </td>
                <td class="center" style="color: #642c90">
                  {{ item.Accuracy }}
                </td>
                <td class="center" style="color: #642c90">{{ item.GlobalAccuracy }}%</td>
                <td class="center" style="color: #642c90">
                  {{ formatTime(item.PracticeTimeInHours) }}
                </td>
                <td class="center" style="color: #642c90">
                  {{ item.ChaptersPracticed }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
import ReportHeader from "./ReportHeader.vue";

// Vue.filter("formatTime", (value) => {
//   if (value) {
//     const decimalTimeString = value;
//     const n = new Date(0, 0);
//     n.setSeconds(+decimalTimeString * 60 * 60);
//     return n.toTimeString().slice(0, 5);
//   }
//   return 0;
// });

export default {
  name: "ReportPracticeCoverage",
  components: {
    ReportHeader,
  },
  data() {
    return {
      tableHeader: [],
      tableBody: [],
      formSubmitInfo: false,
      tableBodyCloneData: [],
      sortedData: [],
      sortByOption: "",
      isSortClicked: false,
      todayDate: "",
      pastDate: "",
    };
  },
  activated() {
    this.tableBody = [];
    this.formSubmitInfo = false;
  },
  created() {
    //     this.tableBody = [];
    //     this.formSubmitInfo = false;
  },
  methods: {
    formatTime(value) {
      if (value) {
        const decimalTimeString = value;
        const n = new Date(0, 0);
        n.setSeconds(+decimalTimeString * 60 * 60);
        return n.toTimeString().slice(0, 5);
      }
      return 0;
    },
    getPastWeekData(tbody) {
      this.tableBody = tbody;
      this.tableBodyCloneData = tbody;
      if (this.tableBody !== []) {
        this.tableBody = this.tableBody.filter((item) => item.SubjectId === 0);
      }
    },
    getFormSubmitTrigger(triggerBoolean) {
      this.formSubmitInfo = triggerBoolean;
      // todays date
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = today.toLocaleString("default", { month: "short" });
      today = `${dd} ${mm}`;
      this.todayDate = today;
      // past week date
      let backday = new Date();
      const pastDate = backday.getDate() - 7;
      backday.setDate(pastDate);
      const backdd = String(backday.getDate()).padStart(2, "0");
      const backmm = backday.toLocaleString("default", { month: "short" });
      backday = `${backdd} ${backmm}`;
      this.pastDate = backday;
    },
    // Sort By
    selectedMultipleSubjectId(subjectId) {
      if (subjectId.length > 0) {
        this.selectedSubjectId = subjectId;
        const newData = [];
        this.selectedSubjectId.forEach((item) => {
          newData.push(this.tableBodyCloneData.filter((item1) => item1.SubjectId === item));
        });
        const tempData = newData.flat(1);
        if (tempData) {
          this.tableBody = [];
          this.tableBody = tempData;
        }
      } else {
        this.getPastWeekData(this.tableBodyCloneData);
        this.selectedSubjectId = [];
      }
    },

    QsAttempt() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.QuestionsAttempted - b.QuestionsAttempted);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.QuestionsAttempted - a.QuestionsAttempted);
        this.isSortClicked = true;
      }
    },
    SortByQsCorrect() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.QuestionsCorrect - b.QuestionsCorrect);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.QuestionsCorrect - a.QuestionsCorrect);
        this.isSortClicked = true;
      }
    },
    SortByAccuracy() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.Accuracy - b.Accuracy);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.Accuracy - a.Accuracy);
        this.isSortClicked = true;
      }
    },
    SortByGlobalAccuracy() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.GlobalAccuracy - b.GlobalAccuracy);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.GlobalAccuracy - a.GlobalAccuracy);
        this.isSortClicked = true;
      }
    },
    SortByPracticeTime() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.PracticeTimeInHours - b.PracticeTimeInHours);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.PracticeTimeInHours - a.PracticeTimeInHours);
        this.isSortClicked = true;
      }
    },
    sortByChaptersPracticed() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.ChaptersPracticed - b.ChaptersPracticed);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.ChaptersPracticed - a.ChaptersPracticed);
        this.isSortClicked = true;
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

td {
  border-radius: 0px;
}

table.qtl {
  border-collapse: separate;
  border-spacing: 0 10px;
}

thead {
  border-radius: 5px 0 0 5px;
}

table.qtl thead tr {
  background-color: #6a2f85;
  color: #fff;
}

table.qtl thead tr th {
  border-radius: 0;
}

.size {
  width: 200px;
}

.icon {
  cursor: pointer;
}
</style>
