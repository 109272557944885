<template>
  <div>
    <div class="course-heading">
      <h1>
        Report / <span>{{ ReportTitle }}</span>
      </h1>
      <h1></h1>
    </div>
    <div class="course-form">
      <div class="row" style="margin-bottom: 16px">
        <!-- Course -->
        <div class="col l3 m6 s12 dtitle">
          <strong>Course</strong>
          <select
            class="browser-default dropd"
            @change="onCourseChange($event)"
            v-model="courseName"
          >
            <option value="0" disabled selected>--Select--</option>
            <option v-for="(course, index) in filterCourse" :key="index" :value="course.courseId">
              {{ course.courseName }}
            </option>
          </select>
          <p class="error" v-show="showCourseError">Please select course</p>
        </div>
        <!-- Batch -->
        <div class="col l3 m6 s12 dtitle">
          <strong>Batch</strong>
          <select class="browser-default dropd" @change="onBatchChange($event)" v-model="batchName">
            <option value="0" disabled selected>--Select--</option>
            <option v-for="(batch, index) in batchList" :key="index" :value="batch.BatchId">
              {{ batch.BatchName }}
            </option>
          </select>
          <p class="error" v-show="showBatchError">Please select batch</p>
        </div>

        <!-- CLass -->
        <div class="col l3 m6 s12 dtitle" v-if="ReportTitle === 'Chapter Dashboard'">
          <strong>Class</strong>
          <select class="browser-default dropd" @change="onClassChange($event)" v-model="className">
            <option value="0" disabled selected>--Select--</option>
            // eslint-disable-next-line vue/valid-v-for
            <option v-for="(classdata, index) in classList" :key="index" :value="classdata.id">
              {{ classdata.displayName }}
            </option>
          </select>
          <p class="error" v-show="showClassError">Please select class</p>
        </div>

        <!-- Subject -->
        <div
          class="col l3 m6 s12 dtitle"
          v-if="
            ReportTitle === 'Practice Coverage' ||
            ReportTitle === 'Institute Test Coverage' ||
            ReportTitle === 'Chapter Dashboard'
          "
        >
          <strong>Subject</strong>
          <select
            class="browser-default dropd"
            @change="onSubjectChange($event)"
            v-model="subjectName"
          >
            <option value="0" disabled selected>--Select--</option>
            <option v-for="(subject, index) in subjectList" :key="index" :value="subject.SubjectId">
              {{ subject.SubjectName }}
            </option>
          </select>
          <p class="error" v-show="showSubjectError">Please select subject</p>
        </div>
        <!-- Multiple Subject Filter Select-->
        <div
          class="col l3 m6 s12 dtitle"
          v-if="
            (ReportTitle === 'Past Week Practice' && sortByMultipleSubject) ||
            (ReportTitle === 'Institute Test Performance' && sortByMultipleSubject)
          "
        >
          <strong>Subject</strong>
          <!-- <h3 style="font-size: 18px;color: #333333;">Subject</h3> -->
          <div class="dropdown-list">
            <div
              class="selected-option"
              @click="showMultipleSubjectOption = !showMultipleSubjectOption"
            >
              <p v-if="selectedMultipleSubjectId.length === 1">
                {{
                  selectMultiQues.subjectName !== "All Subjects" ? selectMultiQues.subjectName : ""
                }}
              </p>
              <p v-if="selectedMultipleSubjectId.length >= 1">
                {{ selectMultiQues.subjectName === "All Subjects" ? "All Subjects" : "" }}
              </p>
              <p v-if="selectedMultipleSubjectId.length > 1">
                {{
                  selectMultiQues.subjectName !== "All Subjects"
                    ? selectMultiQues.subjectName + " and more"
                    : ""
                }}
              </p>
              <p v-if="selectedMultipleSubjectId.length === 0">--Select--</p>
            </div>
            <div
              class="select-option-card"
              v-if="showMultipleSubjectOption"
              v-click-outside="onClickOutside"
            >
              <ul>
                <p v-for="(subject, index) in subjectList" :key="index">
                  <label>
                    <!-- <input type="checkbox" class="filled-in"  checked /> -->
                    <input
                      type="checkbox"
                      class="filled-in"
                      :value="subject.subjectId"
                      v-model="selectedMultipleSubjectId"
                      checked
                    />
                    <span>{{ subject.subjectName }}</span>
                  </label>
                </p>
              </ul>
            </div>
          </div>
        </div>
        <!-- Date filter Select-->
        <!-- <div class="col m3 s6" v-if="ReportTitle === 'Past Week Practice' && sortByDate">
                <h3 style="color: #686868">Date</h3>
                <div class="dropdown-list">
                    <div class="selected-option" @click="showDateOption=!showDateOption">
                        <p v-if="calendar.dateRange !== undefined">
                          <span v-if="calendar.dateRange.start">{{calendar.dateRange.start}} </span>
                          <span v-if="calendar.dateRange.end">- {{calendar.dateRange.end}}</span>
                        </p>
                        <p v-if="calendar.dateRange === undefined">--Select--</p>
                    </div>
                    <div class="select-option-card" v-if="showDateOption" v-click-outside="onClickOutside">
                        <ul class="no-padding">
                            <FunctionalCalendar :key="markedDates" v-model="calendar" :is-date-range="true" :date-format="'dd-mm-yyyy'" :change-month-function="true" :change-year-function="true" :markedDates="['6-3-2021']" class="calendar multiple" ref="Calendar">
                            </FunctionalCalendar>
                        </ul>
                    </div>
                </div>
            </div> -->

        <!-- Chapter -->
        <div class="col l3 m6 s12 dtitle" v-if="ReportTitle === 'Chapter Dashboard'">
          <strong>Chapter</strong>
          <select
            class="browser-default dropd"
            @change="onChapterChange($event)"
            v-model="chapterName"
          >
            <option value="0" disabled selected>--Select--</option>
            <option v-for="(chapter, index) in chapterList" :key="index" :value="chapter.ChapterId">
              {{ chapter.ChapterName }}
            </option>
          </select>
          <p class="error" v-show="showChapterError">Please select chapter</p>
        </div>
        <div class="col l3 m6 s12"></div>

        <!-- Submit -->
        <div class="col s12">
          <button
            class="submit-btn"
            @click="submitPracticeCoverageForm"
            v-if="ReportTitle === 'Practice Coverage'"
          >
            Submit
          </button>
          <button
            class="submit-btn"
            @click="submitInstituteTestCoverageForm"
            v-if="ReportTitle === 'Institute Test Coverage'"
          >
            Submit
          </button>
          <button
            class="submit-btn"
            @click="submitPastWeekPracticeForm"
            v-if="ReportTitle === 'Past Week Practice'"
          >
            Submit
          </button>
          <button
            class="submit-btn"
            @click="submitInstituteTestPerformanceForm"
            v-if="ReportTitle === 'Institute Test Performance'"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
// import {
//   FunctionalCalendar,
// } from "vue-functional-calendar";
// import vClickOutside from "v-click-outside";
import MobileAPI from "../Mobileapi";
import API from "../Api";

// Vue.use(vClickOutside);

export default {
  name: "ReportHeader",
  // components: {
  //   FunctionalCalendar,
  // },
  data() {
    return {
      Courses: [],
      subjectList: [],
      BatchApiData: [],
      batchList: [],
      classList: [],
      chapterList: [],
      courseName: "",
      className: "",
      batchName: "",
      subjectName: "",
      chapterName: "",
      courseId: null,
      batchId: null,
      subjectId: null,
      chapterId: null,
      classId: null,
      calendar: {},
      markedDates: new Date().getDate().toString(),
      showCourseOption: false,
      showBatchOption: false,
      showSubjectOption: false,
      showDateOption: false,
      sortByDate: false,
      isSubmitTriggerOption: false,
      showChapterOption: false,
      showMultipleSubjectOption: false,
      sortByMultipleSubject: false,
      selectedCourseName: "",
      selectedBatchName: "",
      selectedSubjectName: "",
      selectedChapterName: "",
      selectedSortByName: "",
      selectedMultipleSubjectId: [],
      selectedMultipleSubjectName: "",
      isSubmitTrigger: false,
      showCourseError: false,
      showBatchError: false,
      showSubjectError: false,
      showChapterError: false,
      showClassError: false,
      tableHead: [],
      tableBody: [],
    };
  },
  props: {
    ReportTitle: String,
  },

  updated() {
    this.$emit("selectedMultipleSubjectId", this.selectedMultipleSubjectId);
  },
  created() {
    const user = this.$store.getters.user;
    this.isSubmitTrigger = false;
    this.sortByMultipleSubject = false;
    // //console.log("user: ", user);
    API.getBatches(user.AffiliationId, (response) => {
      this.BatchApiData = response.data;
    });
    this.$store.dispatch("showLoader", true);
    MobileAPI.getDataOfAffiliationCourses({}, (response) => {
      this.Courses = response.data;
      // //console.log("coursedata: ", this.Courses);
    });
    this.$store.dispatch("showLoader", false);
  },
  computed: {
    filterCourse() {
      return [...new Map(this.Courses.map((item) => [item.courseId, item])).values()];
    },
    selectMultiQues() {
      return this.subjectList.find((item) => item.subjectId === this.selectedMultipleSubjectId[0]);
    },
  },
  methods: {
    // Select Course
    onCourseChange(event) {
      this.showCourseOption = false;
      this.isSubmitTrigger = false;
      this.sortByDate = false;
      this.sortByMultipleSubject = false;
      this.$emit("selectedMultipleSubjectId", "");
      this.selectedMultipleSubjectId = [];
      this.courseId = Number(event.target.value);
      // //console.log("courseid: ", this.courseId);
      this.$store.dispatch("showLoader", true);
      MobileAPI.getCourseClass(this.courseId, (response) => {
        // console.log("class: ", response);
        this.classList = response.data;
        this.$store.dispatch("showLoader", false);
      });
      const selectedCourse = this.filterCourse.find((item) => item.courseId === this.courseId);
      this.subjectList = this.filterCourse.filter(
        (course) => course.affiliationCourseId === selectedCourse.affiliationCourseId,
      );
      this.subjectList = this.subjectList[0].subjectList;
      // console.log("sub List: ", this.subjectList);
      this.batchList = this.BatchApiData.filter((batch) => batch.CourseId === this.courseId);
    },
    // Select Class
    onClassChange(event) {
      this.isSubmitTrigger = false;
      this.sortByMultipleSubject = false;
      this.showSubjectError = false;
      this.selectedSortByName = "";
      this.classId = event.target.value;
      this.$store.dispatch("showLoader", true);
      MobileAPI.getcourseClassSubjectChapter(this.courseId, this.classId, (response) => {
        // console.log("chapter: ", response);
        this.chapterList = response.data;
        this.$store.dispatch("showLoader", false);
      });
    },
    // Select Subject
    onSubjectChange(event) {
      this.isSubmitTrigger = false;
      this.sortByMultipleSubject = false;
      this.showSubjectError = false;
      this.selectedSortByName = "";
      this.subjectId = Number(event.target.value);
      // console.log("subId: ", this.subjectId);
      // console.log("chapters1: ", this.chapterList);
      if (this.subjectId === 0) {
        this.$store.dispatch("showLoader", true);
        MobileAPI.getcourseClassSubjectChapter(this.courseId, this.classId, (response) => {
          // console.log("chapter: ", response);
          this.chapterList = response.data;
          this.$store.dispatch("showLoader", false);
        });
      } else {
        this.chapterList = this.chapterList.filter((item) => item.SubjectId === this.subjectId);
      }
    },
    // Select Batch
    onBatchChange(event) {
      this.isSubmitTrigger = false;
      this.sortByMultipleSubject = false;
      // //console.log(event.target.value);
      this.batchId = Number(event.target.value);
      // //console.log(this.batchId);
    },
    // Select Chapter
    onChapterChange(event) {
      this.chapterId = event.target.value;
      // console.log("chapterid: ", this.chapterId);
    },
    selectedSortBy(thead) {
      this.selectedSortByName = thead;
      this.isSubmitTriggerOption = false;
    },
    onClickOutside() {
      if (this.showMultipleSubjectOption === true) {
        this.showMultipleSubjectOption = false;
      }
    },
    // Submit Practice Coverage
    submitPracticeCoverageForm() {
      if (this.batchId === null) {
        this.showBatchError = true;
      } else {
        this.showBatchError = false;
      }
      if (this.courseId === null) {
        this.showCourseError = true;
      } else {
        this.showCourseError = false;
      }
      if (this.subjectId === null) {
        this.showSubjectError = true;
      } else {
        this.showSubjectError = false;
      }
      if (this.batchId !== null && this.courseId !== null && this.subjectId !== null) {
        this.$store.dispatch("showLoader", true);
        MobileAPI.getPracticeCoverageReport(
          {
            courseId: this.courseId ? this.courseId : "",
            batchId: this.batchId ? this.batchId : "",
            subjectId: this.subjectId ? this.subjectId : "",
          },
          (response) => {
            if (response.data.length > 0) {
              this.isSubmitTrigger = true;
              this.$emit("formSubmitTrigger", this.isSubmitTrigger);
              this.tableBody = response.data;
              this.$emit("TableBody", this.tableBody);
              this.$store.dispatch("showLoader", false);
            } else {
              this.isSubmitTrigger = true;
              this.$emit("formSubmitTrigger", this.isSubmitTrigge);
              this.$emit("TableBody", []);
              this.$store.dispatch("showLoader", false);
              window.M.toast({
                html: "Record Not found",
              });
            }
          },
        );
      } else {
        this.isSubmitTrigger = false;
        this.$emit("formSubmitTrigger", this.isSubmitTrigger);
      }
    },
    // Submit Institute Test Coverage
    submitInstituteTestCoverageForm() {
      if (this.batchId === null) {
        this.showBatchError = true;
      } else {
        this.showBatchError = false;
      }
      if (this.courseId === null) {
        this.showCourseError = true;
      } else {
        this.showCourseError = false;
      }
      if (this.subjectId === null) {
        this.showSubjectError = true;
      } else {
        this.showSubjectError = false;
      }
      if (this.batchId !== null && this.courseId !== null && this.subjectId !== null) {
        this.$store.dispatch("showLoader", true);
        MobileAPI.getInstituteTestCoverageReport(
          {
            courseId: this.courseId ? this.courseId : "",
            batchId: this.batchId ? this.batchId : "",
            subjectId: this.subjectId ? this.subjectId : "",
          },
          (response) => {
            // //console.log("resp: ", response);
            if (response.data.length > 0) {
              this.isSubmitTrigger = true;
              this.$emit("formSubmitTrigger", this.isSubmitTrigger);
              this.tableBody = response.data;
              this.$emit("TableBody", this.tableBody);
              this.$store.dispatch("showLoader", false);
            } else {
              this.$store.dispatch("showLoader", false);
              window.M.toast({
                html: "Record Not found",
              });
            }
          },
        );
      } else {
        this.isSubmitTrigger = false;
        this.$emit("formSubmitTrigger", this.isSubmitTrigger);
      }
    },
    // submit Past Week Practice Form
    submitPastWeekPracticeForm() {
      if (this.batchId === null) {
        this.showBatchError = true;
      } else {
        this.showBatchError = false;
      }
      if (this.courseId === null) {
        this.showCourseError = true;
      } else {
        this.showCourseError = false;
      }
      // //console.log("Clicked");
      // //console.log("this.courseId: ", this.courseId);
      // //console.log("this.batchId: ", this.batchId);
      // //console.log("this.subjectId: ", this.subjectId);
      if (this.batchId !== null && this.courseId !== null) {
        this.$store.dispatch("showLoader", true);
        MobileAPI.getPastWeekPracticeReport(
          {
            courseId: this.courseId ? this.courseId : "",
            batchId: this.batchId ? this.batchId : "",
          },
          (response) => {
            // console.log("resp: ", response.data);
            if (response.data.length > 0) {
              this.isSubmitTrigger = true;
              this.sortByDate = true;
              this.sortByMultipleSubject = true;
              this.$emit("formSubmitTrigger", this.isSubmitTrigger);
              this.tableBody = response.data;
              this.$emit("TableBody", this.tableBody);
              this.$store.dispatch("showLoader", false);
            } else {
              this.$store.dispatch("showLoader", false);
              window.M.toast({
                html: "Record Not found",
              });
            }
          },
        );
      } else {
        this.isSubmitTrigger = false;
        this.$emit("formSubmitTrigger", this.isSubmitTrigger);
      }
    },
    // submit institute test performance form
    submitInstituteTestPerformanceForm() {
      if (this.batchId === null) {
        this.showBatchError = true;
      } else {
        this.showBatchError = false;
      }
      if (this.courseId === null) {
        this.showCourseError = true;
      } else {
        this.showCourseError = false;
      }
      if (this.batchId !== null && this.courseId !== null) {
        // //console.log("this.batchId: ", this.batchId);
        // //console.log("this.courseid: ", this.courseId);
        this.$store.dispatch("showLoader", true);
        MobileAPI.getInstituteTestPerformanceReport(
          {
            courseId: this.courseId ? this.courseId : "",
            batchId: this.batchId ? this.batchId : "",
          },
          (response) => {
            // //console.log("res: ", response.data);
            if (response.data.length > 0) {
              this.isSubmitTrigger = true;
              this.sortByMultipleSubject = true;
              this.$emit("formSubmitTrigger", this.isSubmitTrigger);
              this.tableBody = response.data;
              this.$emit("TableBody", this.tableBody);
              this.$store.dispatch("showLoader", false);
            } else {
              this.$store.dispatch("showLoader", false);
              window.M.toast({
                html: "Record Not found",
              });
            }
          },
        );
      } else {
        this.isSubmitTrigger = false;
        this.$emit("formSubmitTrigger", this.isSubmitTrigger);
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0%;
}

select {
  display: block;
}

.course-heading {
  padding: 18px 0;
  border-bottom: 1px solid #e0e0e0;
}

.course-heading h1 {
  font-size: 20px;
  font-weight: bold;
  color: #575858;
}

.course-heading h1 span {
  color: #8155a6;
}

.course-form {
  background: #fff;
  padding: 15px 5px 1px 5px;
  margin-top: 25px;
  border-radius: 5px;
}

.dropdown-list {
  position: relative;
  width: 100%;
  top: 8px;
}

.selected-option {
  border: 1px solid #ccc;
  padding: 4px 12px;
  background-position: center right;
  background-origin: content-box;
  cursor: pointer;
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 36px;
}

.selected-option::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 7px;
  height: 7px;
  margin-top: -2px;
  border-bottom: 2px solid #333333;
  border-right: 2px solid #333333;
  transform: rotate(45deg);
  cursor: pointer;
}

.selected-option p {
  margin: 0;
  color: #333333;
}

.select-option-card {
  background: #fff;
  border: 2px solid #e5e5e5;
  position: absolute;
  width: 100%;
  max-height: 250px;
  top: 40px;
  display: block;
  z-index: 10;
  border-radius: 8px;
  overflow-y: scroll;
}

.select-option-card::-webkit-scrollbar {
  width: 0;
}

.select-option-card::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.select-option-card::-webkit-scrollbar-thumb {
  background-color: #837b7b;
  border-radius: 5px;
}

.select-option-card ul {
  list-style-type: none;
  margin: 0;
}

.select-option-card ul li {
  padding: 10px 12px;
  cursor: pointer;
}

.select-option-card ul li:hover {
  background: #f4f4f4;
}

.select-box select::-webkit-scrollbar {
  display: none;
}

.select-box select::-moz-scrollbar {
  display: none;
}

.select-box select::-o-scrollbar {
  display: none;
}

.select-box select::-google-ms-scrollbar {
  display: none;
}

.select-box select::-khtml-scrollbar {
  display: none;
}

.error {
  color: red;
  position: relative;
  /* top: 40px; */
}

.course-form .submit-btn {
  margin-top: 30px;
  padding: 8px 33px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 5px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  cursor: pointer;
  width: 115px;
  height: 35px;
}

/* Multiple Checkbox */
.select-option-card ul {
  padding: 0 15px;
}

.select-option-card p {
  display: flex;
  width: 100%;
  margin: 10px 0;
}

[type="checkbox"].filled-in:checked + span:not(.lever):before {
  top: 1px;
  left: 2px;
  width: 5px !important;
  height: 7px !important;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #642c90;
  border-bottom: 2px solid #642c90;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}

[type="checkbox"].filled-in:not(:checked) + span:not(.lever):before {
  width: 5px;
  height: 7px;
  border: 3px solid transparent;
  left: 2px;
  top: 1px;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}

[type="checkbox"].filled-in:checked + span:not(.lever):after {
  top: 1px;
  position: absolute;
  width: 14px !important;
  height: 14px !important;
  border: 2px solid #642c90 !important;
  background-color: transparent;
  z-index: 0;
}

[type="checkbox"].filled-in:not(:checked) + span:not(.lever):after {
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 2px solid #333333 !important;
  top: 1px;
  z-index: 0;
}

label {
  color: #333333;
}

[type="checkbox"] + span:not(.lever) {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
  height: 15px;
  line-height: 15px;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dtitle {
  font-size: 18px;
  color: #333333;
  margin-bottom: 5px;
}

.dropd {
  border-radius: 5px;
  height: 36px;
  margin-top: 8px;
}

@media only screen and (max-width: 600px) {
  .dropdown-list {
    margin-bottom: 60px;
  }

  .course-form .submit-btn {
    margin-top: 0;
  }

  .course-heading h1 {
    margin-left: 10px;
  }
}
</style>
