<template>
<div>
    <ReportHeader ReportTitle="Institute Test Coverage" @TableBody="getTableBody($event)" @formSubmitTrigger="getFormSubmitTrigger($event)"/>
    <div class="chapter-coverage-content" v-if="formSubmitInfo">
        <div class="row">
            <div class="col s12" style="padding:0">
                                <table class="qtl" style="width: 100%">
                    <thead>
                        <tr>
                            <th class="left-align">Chapter</th>
                            <th class="center">Total Qs <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="SortByTotalQuestion()">swap_vert</span></th>
                            <th class="center-align">Distinct Qs<span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="sortByDistinctQues()">swap_vert</span></th>
                            <th class="center-align">Tests<span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="sortByNoOfTest()">swap_vert</span></th>
                            <th class="center-align">Students<span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="sortByNoOfStudent()">swap_vert</span></th>
                            <th class="center-align">Attempt <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="SortByAttempt()">swap_vert</span></th>
                            <th class="center-align">Accuracy <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="SortByAccuracy()">swap_vert</span></th>
                            <th class="center-align">National Accuracy <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="SortByGlobalAccuracy()">swap_vert</span></th>
                            <th class="center-align">Total Time Spend (in hrs) <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="TotalTimeSpend()">swap_vert</span></th>
                            <th class="center-align">Avg Time/Student (in min) <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="AvgTimeSpend()">swap_vert</span></th>
                        </tr>
                    </thead>
                    <tbody class="card-panel">
                        <tr v-for="(item, index) in tableBody" :key="index" style="border-radius: 100px">
                            <td class=" size" style="font--size: 14px; font-weight: 600; color: #642c90; text-transform: capitalize;">
                                {{item.ChapterName}}
                            </td>
                            <td class="center" style="color: #333333;">
                                {{item.TotalQuestions}}
                            </td>
                            <td class="center" style="color: #642C90;">
                                {{item.DistinctQuestions}}
                            </td>
                            <td class="center" style="color: #37BC16;">
                                {{item.NumberOfTests}}
                            </td>
                            <td class="center" style="color: #37BC16;">
                                {{item.NumberOfStudents}}
                            </td>
                            <td class="center" style="color: #642C90;">
                                {{item.AttemptPercentage}}%
                            </td>
                            <td class="center" style="color: #642C90;">
                                {{item.Accuracy}}%
                            </td>
                            <td class="center" style="color: #642C90;">
                                {{item.GlobalAccuracy}}%
                            </td>
                            <td class="center" style="color: #642C90;">
                               {{formatHour(item.TotalTimeSpentInHours)}}
                            </td>
                            <td class="center" style="color: #642C90;">
                               {{formatMin(item.AverageTimePerStudentInMin)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import Vue from "vue";
// import moment from "moment";
import ReportHeader from "./ReportHeader.vue";

// Vue.filter("formatHour", (value) => {
//   if (value) {
//     const decimalTimeString = value;
//     const n = new Date(0, 0);
//     n.setSeconds(+decimalTimeString * 60 * 60);
//     return n.toTimeString().slice(0, 5);
//   }
//   return 0;
// });
// Vue.filter("formatMin", (value) => {
//   if (value) {
//     const num = value;
//     const hours = (num / 60);
//     const rhours = Math.floor(hours);
//     const minutes = (hours - rhours) * 60;
//     const rminutes = Math.round(minutes);
//     return `${rhours}:${rminutes}`;
//   }
//   return 0;
// });
export default {
  name: "ReportInstituteTestCoverage",
  components: {
    ReportHeader,
  },
  data() {
    return {
      tableHeader: [],
      tableBody: [],
      formSubmitInfo: false,
      sortedData: [],
      sortByOption: "",
      isSortClicked: false,
    };
  },
  activated() {
    this.tableBody = [];
    this.formSubmitInfo = false;
  },
  methods: {
    formatHour(value) {
      if (value) {
        const num = value;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return `${rhours}:${rminutes}`;
      }
      return 0;
    },
    formatMin(value) {
      if (value) {
        const num = value;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return `${rhours}:${rminutes}`;
      }
      return 0;
    },
    getTableBody(tbody) {
      this.tableBody = tbody;
    },
    getFormSubmitTrigger(triggerBoolean) {
      this.formSubmitInfo = triggerBoolean;
    },
    SortByTotalQuestion() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.TotalQuestions - b.TotalQuestions);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.TotalQuestions - a.TotalQuestions);
        this.isSortClicked = true;
      }
    },
    SortByAttempt() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.AttemptPercentage - b.AttemptPercentage);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.AttemptPercentage - a.AttemptPercentage);
        this.isSortClicked = true;
      }
    },
    SortByAccuracy() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.Accuracy - b.Accuracy);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.Accuracy - a.Accuracy);
        this.isSortClicked = true;
      }
    },
    sortByNoOfTest() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.NumberOfTests - b.NumberOfTests);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.NumberOfTests - a.NumberOfTests);
        this.isSortClicked = true;
      }
    },
    sortByNoOfStudent() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.NumberOfStudents - b.NumberOfStudents);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.NumberOfStudents - a.NumberOfStudents);
        this.isSortClicked = true;
      }
    },
    SortByGlobalAccuracy() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.GlobalAccuracy - b.GlobalAccuracy);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.GlobalAccuracy - a.GlobalAccuracy);
        this.isSortClicked = true;
      }
    },
    sortByDistinctQues() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.DistinctQuestions - b.DistinctQuestions);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.DistinctQuestions - a.DistinctQuestions);
        this.isSortClicked = true;
      }
    },
    TotalTimeSpend() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.TotalTimeSpentInHours - b.TotalTimeSpentInHours);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.TotalTimeSpentInHours - a.TotalTimeSpentInHours);
        this.isSortClicked = true;
      }
    },
    AvgTimeSpend() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.AverageTimePerStudentInMin - b.AverageTimePerStudentInMin);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.AverageTimePerStudentInMin - a.AverageTimePerStudentInMin);
        this.isSortClicked = true;
      }
    },
  },
};
</script>

<style scoped>
* {
    box-sizing: border-box;
}

td {
    border-radius: 0px;
}

table.qtl {
    border-collapse: separate;
    border-spacing: 0 10px;
}

thead {
    border-radius: 5px 0 0 5px;
}

table.qtl thead tr {
    background-color: #6a2f85;
    color: #fff;
}

table.qtl thead tr th {
    border-radius: 0;
}

.size {
    width: 200px;
}

.icon {
    cursor: pointer;
}

</style>
