<template>
  <PageNavigator
    :pageList="affiliationId === 18 && role === 'Affiliation Head' ? pageList2 : pageList"
  />
</template>

<script>
import AffiliationDetails from "./AffiliationDetails.vue";
import PageNavigator from "../components/PageNavigator.vue";
import PracticeCoverage from "./PracticeCoverage.vue";
import PastWeekPractice from "./PastWeekPractice.vue";
import InstituteTestCoverage from "./InstituteTestCoverage.vue";
import InstituteTestPerformance from "./InstituteTestPerformance.vue";
import CustomReports from "./CustomReports.vue";

export default {
  components: {
    PageNavigator,
  },
  created() {
    this.userDetails = this.$store.getters.user;
    this.role = this.userDetails.role;
    this.affiliationId = Number(this.userDetails.AffiliationId);
  },
  data() {
    return {
      affiliationId: null,
      userDetails: "",
      role: "",
      pageList: [
        {
          componentName: "PracticeCoverage",
          component: PracticeCoverage,
          text: "Practice Coverage",
        },
        {
          componentName: "PastWeekPractice",
          component: PastWeekPractice,
          text: "Past Week Practice",
        },
        {
          componentName: "InstituteTestCoverage",
          component: InstituteTestCoverage,
          text: "Institute Test Coverage",
        },
        {
          componentName: "InstituteTestPerformance",
          component: InstituteTestPerformance,
          text: "Institute Test Performance",
        },
        {
          componentName: "PracticeSelftestDetailsStudent",
          component: CustomReports,
          text: "Practice & Self test Details (Student wise)",
          props: {
            apiMethod: "getPracticeSelfTestData",
            name: "Practice & Self test details-Student wise",
            showQuestion: false,
          },
        },
        {
          componentName: "PracticeSelftestDetailsBatch",
          component: CustomReports,
          text: "Practice & Self test Details (Batch wise)",
          props: {
            apiMethod: "getPracticeSelfTestDataBatchwise",
            name: "Practice & Self test details-Batch wise",
            showQuestion: false,
          },
        },
        {
          componentName: "PracticeSelftestDetailsChapter",
          component: CustomReports,
          text: "Practice & Self test Details (Chapter wise)",
          props: {
            apiMethod: "getPracticeSelfTestDataChapterwise",
            name: "Practice & Self test details-Chapter wise",
            showQuestion: false,
          },
        },
        {
          componentName: "LowAccuracyQs",
          component: CustomReports,
          text: "Tricky questions",
          props: { apiMethod: "getLowAccuracyQs", name: "Tricky questions", showQuestion: true },
        },
        {
          componentName: "StudentsNotPracticing",
          component: CustomReports,
          text: "No effort Students",
          props: {
            apiMethod: "getStudentsNotPracticing",
            name: "No effort Students",
            showQuestion: false,
          },
        },
      ],
      pageList2: [
        {
          componentName: "AffiliationDetails",
          component: AffiliationDetails,
          text: "Affiliation Details",
        },
        {
          componentName: "PracticeCoverage",
          component: PracticeCoverage,
          text: "Practice Coverage",
        },
        {
          componentName: "PastWeekPractice",
          component: PastWeekPractice,
          text: "Past Week Practice",
        },
        {
          componentName: "InstituteTestCoverage",
          component: InstituteTestCoverage,
          text: "Institute Test Coverage",
        },
        {
          componentName: "InstituteTestPerformance",
          component: InstituteTestPerformance,
          text: "Institute Test Performance",
        },
        {
          componentName: "PracticeSelftestDetailsStudent",
          component: CustomReports,
          text: "Practice & Self test Details (Student wise)",
          props: {
            apiMethod: "getPracticeSelfTestData",
            name: "Practice & Self test details-Student wise",
            showQuestion: false,
          },
        },
        {
          componentName: "PracticeSelftestDetailsBatch",
          component: CustomReports,
          text: "Practice & Self test Details (Batch wise)",
          props: {
            apiMethod: "getPracticeSelfTestDataBatchwise",
            name: "Practice & Self test details-Batch wise",
            showQuestion: false,
          },
        },
        {
          componentName: "PracticeSelftestDetailsChapter",
          component: CustomReports,
          text: "Practice & Self test Details (Chapter wise)",
          props: {
            apiMethod: "getPracticeSelfTestDataChapterwise",
            name: "Practice & Self test details-Chapter wise",
            showQuestion: false,
          },
        },
        {
          componentName: "LowAccuracyQs",
          component: CustomReports,
          text: "Tricky questions",
          props: { apiMethod: "getLowAccuracyQs", name: "Tricky questions", showQuestion: true },
        },
        {
          componentName: "StudentsNotPracticing",
          component: CustomReports,
          text: "No effort Students",
          props: {
            apiMethod: "getStudentsNotPracticing",
            name: "No effort Students",
            showQuestion: false,
          },
        },
      ],
    };
  },
};
</script>

<style scoped></style>
