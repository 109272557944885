<template>
  <div class="relative navigation-bar" :class="{active: openMenu}">
    <div style="position: absolute;color: #6a2f85;left: 15px;top: 20px; z-index: 100;" @click="openMenu = !openMenu"> <span class="material-icons material-symbols-outlined showOnSmall fw-600">{{openMenu ? 'close' : 'menu'}}</span> </div>
      <div class="absolute nav-links is-large-screen" :class="{active: openMenu}">
          <h5 class="fs-17 pointer links fw-600" v-for="(page, index) in pageList" :class="{active: currentTab === page.componentName}" :key="index" @click="currentTab = page.componentName; openMenu=false;">{{ page.text }}</h5>
      </div>
  </div>
  <div class="scroll-verticle-bar absolute page-viewer scroll-horizontal">
      <div v-for="(page, index) in pageList" :key="index">
          <component :is="page.component" class="tab" v-if="currentTab === page.componentName" v-bind="page.props"></component>
      </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      currentTab: null,
      openMenu: false,
    };
  },
  props: {
    pageList: Array,
  },
  beforeMount() {
    document.body.style.backgroundColor = "#fff";
  },
  created() {
    this.currentTab = this.pageList[0].componentName;
  },
};
</script>

<style scoped>
.navigation-bar {
width: 220px;
height: calc(100vh - 64px);
float: left;
}

.nav-links {
width: 100%;
height: 100%;
background: #ebedf0;
padding-top: 12px;
padding-left: 10px;
transition: .3s all ease-in;
z-index: 99
}
.links{
  padding: 15px 0;
  padding-left: 20px;
  margin: 2px 0;
  position: relative;
  color: #333333;
  overflow: hidden;
  transition: .3s all ease-in;
}
.links::after, .links.active::after{
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  right: -100%;
  background: linear-gradient(92deg, #6a2f85, #6a2f8599);
  transition: .3s all ease-in;
  border-radius: 30px 0px 0px 30px;
  z-index: -1;
}
.links:hover::after, .links.active::after{
  right: 0;
}
.links:hover, .links.active{
  z-index: 1;
  color: white;
}
.is-large-screen{
left: 0;
}

.page-viewer {
width: calc(100vw - 230px);
height: calc(100vh - 64px);
right: 0px;
padding-right: 10px;
display: block;
}

.material-icons {
font-family: 'Material Icons';
font-weight: normal;
font-style: normal;
font-size: 27px;
line-height: 1;
letter-spacing: normal;
text-transform: none;
display: inline-block;
white-space: nowrap;
word-wrap: normal;
direction: ltr;
-webkit-font-feature-settings: 'liga';
-webkit-font-smoothing: antialiased;
cursor: pointer;
}
.showOnSmall{
display: none;
}

@media screen and (max-width: 990px) {
  .page-viewer {
width: calc(100vw - 70px);
  }
.showOnSmall{
display: block;
}
.is-large-screen{
left: -100% !important;
}
.nav-links{
padding-top: 50px;
}
.is-large-screen.active {
left: 0 !important;
}
.navigation-bar{
  width: 60px;
  height: calc(100vh - 56px);
background: #ebedf0;
transition: .3s all ease-in;
}
.navigation-bar.active{
  width: 220px;
}
}
</style>
