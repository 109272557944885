<template>
<div>
    <ReportHeader ReportTitle="Practice Coverage" @TableBody="getChapterCoverageBody($event)" @formSubmitTrigger="getFormSubmitTrigger($event)" />
    <div class="chapter-coverage-content" v-if="formSubmitInfo">
        <div class="row">
            <div class="col s12" style="padding:0">
                <table class="qtl" style="width: 100%">
                    <thead>
                        <tr>
                            <th class="left-align">Chapter</th>
                            <th class="center">
                                Qs Count <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="SortByTotalQuestion()">swap_vert</span>
                            </th>
                            <th class="center-align">
                                Unique Qs <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="DistinctQuesPractice()">swap_vert</span>
                            </th>
                            <th class="center-align">
                                Students <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="NoOfStudent()">swap_vert</span>
                            </th>
                            <th class="center-align">
                                Attempt <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="SortByAttempt()">swap_vert</span>
                            </th>
                            <th class="center-align">
                                Accuracy <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="SortByAccuracy()">swap_vert</span>
                            </th>
                            <th class="center-align">
                                National Accuracy <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="SortByGlobalAccuracy()">swap_vert</span>
                            </th>
                            <th class="center-align">
                                Practice Time(in hrs) <span class="material-icons" style="position: relative; top: 6px; margin-left: 2px; cursor: pointer;" @click="sortByPracticeTime()">swap_vert</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="card-panel">
                        <tr v-for="(item, index) in tableBody" :key="index" style="border-radius: 100px">
                            <td class=" size" style="font--size: 14px; font-weight: 600; color: #642c90; text-transform: capitalize;"> {{item.ChapterName}}
                            </td>
                            <td class="center" style="color: #333333;">
                                {{item.TotalQuestionsPracticed}}
                            </td>
                            <td class="center" style="color: #37BC16;">
                                {{item.DistinctQuestionsPracticed}}
                            </td>
                            <td class="center" style="color: #642C90;">
                                {{item.NumberOfStudents}}
                            </td>
                            <td class="center" style="color: #642C90;">
                                {{item.AttemptPercentage}}%
                            </td>
                            <td class="center" style="color: #642C90;">
                                {{item.Accuracy}}%
                            </td>
                            <td class="center" style="color: #642C90;">
                                {{item.GlobalAccuracy}}%
                            </td>
                            <td class="center" style="color: #642C90;">
                                {{formatTime(item.PracticeTimeInHours)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import Vue from "vue";
import ReportHeader from "./ReportHeader.vue";

// Vue.filter("formatTime", (value) => {
//   if (value) {
//     const decimalTimeString = value;
//     const n = new Date(0, 0);
//     n.setSeconds(+decimalTimeString * 60 * 60);
//     return n.toTimeString().slice(0, 5);
//   }
//   return 0;
// });

export default {
  name: "ReportPracticeCoverage",
  components: {
    ReportHeader,
  },
  data() {
    return {
      tableHeader: [],
      tableBody: [],
      formSubmitInfo: false,
      sortedData: [],
      sortByOption: "",
      isSortClicked: false,
    };
  },
  activated() {
    this.tableBody = [];
    this.formSubmitInfo = false;
  },
  created() {
    this.tableBody = [];
    this.formSubmitInfo = false;
  },
  methods: {
    formatTime(value) {
      if (value) {
        const decimalTimeString = value;
        const n = new Date(0, 0);
        n.setSeconds(+decimalTimeString * 60 * 60);
        return n.toTimeString().slice(0, 5);
      }
      return 0;
    },
    getChapterCoverageBody(tbody) {
      this.tableBody = tbody;
    },
    getFormSubmitTrigger(triggerBoolean) {
      this.formSubmitInfo = triggerBoolean;
    },
    SortByTotalQuestion() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.TotalQuestionsPracticed - b.TotalQuestionsPracticed);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.TotalQuestionsPracticed - a.TotalQuestionsPracticed);
        this.isSortClicked = true;
      }
    },
    NoOfStudent() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.NumberOfStudents - b.NumberOfStudents);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.NumberOfStudents - a.NumberOfStudents);
        this.isSortClicked = true;
      }
    },
    SortByAttempt() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.AttemptPercentage - b.AttemptPercentage);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.AttemptPercentage - a.AttemptPercentage);
        this.isSortClicked = true;
      }
    },
    SortByAccuracy() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.Accuracy - b.Accuracy);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.Accuracy - a.Accuracy);
        this.isSortClicked = true;
      }
    },
    SortByGlobalAccuracy() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.GlobalAccuracy - b.GlobalAccuracy);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.GlobalAccuracy - a.GlobalAccuracy);
        this.isSortClicked = true;
      }
    },
    DistinctQuesPractice() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.DistinctQuestionsPracticed - b.DistinctQuestionsPracticed);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.DistinctQuestionsPracticed - a.DistinctQuestionsPracticed);
        this.isSortClicked = true;
      }
    },
    sortByPracticeTime() {
      if (this.isSortClicked) {
        this.tableBody.sort((a, b) => a.PracticeTimeInHours - b.PracticeTimeInHours);
        this.isSortClicked = false;
      } else {
        this.tableBody.sort((a, b) => b.PracticeTimeInHours - a.PracticeTimeInHours);
        this.isSortClicked = true;
      }
    },
  },
};
</script>

<style scoped>
* {
    box-sizing: border-box;
}

td {
    border-radius: 0px;
}

table.qtl {
    border-collapse: separate;
    border-spacing: 0 10px;
}

thead {
    border-radius: 5px 0 0 5px;
}

table.qtl thead tr {
    background-color: #6a2f85;
    color: #fff;
}

table.qtl thead tr th {
    border-radius: 0;
}

.size {
    width: 200px;
}

.icon {
    cursor: pointer;
}

</style>
